import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { AdsSectionAppearance } from '@/components/AdsSection/AdsSection.types';
import { MAIN_CONTAINER_HEIGHT } from '@/styles/constants';
import { Container } from '@/components/Container/Container.styles';

function getTitleWrapperStyles(appearance?: AdsSectionAppearance) {
  switch (appearance) {
    case 'titleWithBtn': {
      return css`
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
        background-color: ${({ theme }) => theme.palette.secondary.background};
      `;
    }
    default: {
      return css``;
    }
  }
}

function getTitleBoxStyles(appearance?: AdsSectionAppearance) {
  switch (appearance) {
    case 'titleWithBtn': {
      return css`
        justify-content: space-between;
      `;
    }
    case 'onlyTitle': {
      return css`
        justify-content: center;
      `;
    }
    default: {
      return css``;
    }
  }
}

export const Box = styled.div<{
  $hasTopMargin?: boolean;
  $appearance?: AdsSectionAppearance;
}>`
  ${({ $hasTopMargin }) =>
    $hasTopMargin &&
    css`
      margin-top: 15px;
    `}

  ${({ $appearance }) =>
    $appearance === 'onlyTitle' &&
    css`
      background-color: ${({ theme }) => theme.palette.primary.background};
    `}
`;

export const TitleWrapper = styled.div<{ $appearance?: AdsSectionAppearance }>`
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};

  ${({ $appearance }) => getTitleWrapperStyles($appearance)}
`;

export const TitleBox = styled(Container)<{
  $appearance?: AdsSectionAppearance;
}>`
  display: flex;
  align-items: center;
  padding: 14px 15px;

  ${({ $appearance }) => getTitleBoxStyles($appearance)}
`;

export const TitleV2 = styled(Typography.Header)<{
  $appearance?: AdsSectionAppearance;
}>`
  max-width: ${MAIN_CONTAINER_HEIGHT}px;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.primary.labels};
  padding: 20px 15px 5px;
`;

export const Count = styled(Typography.Body)`
  color: ${({ theme }) => theme.palette.secondary.labels};
  padding-left: 5px;
`;

export const Title = styled(Typography.Title)<{
  $appearance?: AdsSectionAppearance;
}>`
  color: ${({ theme }) => theme.palette.secondary.labels};
  text-transform: uppercase;
`;

export const More = styled(Typography.Body)`
  color: ${({ theme }) => theme.palette.system.blue};
`;

export const MoreBottomBox = styled.div`
  padding: 5px 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
