import { useEffect, useState } from 'react';
import { Ad, ServiceName, GetAdsResponse } from '@/api/types/ads.types';
import { useQuery } from '@apollo/client';
import { GET_ADS } from '@/api/queries/ads.queries';
import { AdsNextCursor } from '@/store/useAdsStore';
import { COUNT_TO_FIND_DUPLICATES } from '@/hooks/useAdsWithInfiniteScroll/useAdsWithInfiniteScroll.constants';

export function useAdsWithInfiniteScroll({
  adKind,
  maxCount = 1000,
  ads,
  setAds,
  nextCursor,
  setNextCursor,
}: {
  adKind: ServiceName;
  maxCount?: number;
  ads: Ad[];
  setAds: (ads: Ad[]) => void;
  nextCursor: AdsNextCursor;
  setNextCursor: (nextCursor: AdsNextCursor) => void;
  countToFindDuplicates?: number;
}) {
  const [isFinished, setIsFinished] = useState(false);

  const { data } = useQuery<GetAdsResponse>(GET_ADS(adKind), {
    fetchPolicy: 'no-cache',
    variables: { adKind, first: 24, after: nextCursor },
  });

  function onLoadMore() {
    if (isFinished) return;

    const newAds = data?.ads?.nodes;

    setIsFinished(newAds?.length === 0);

    if (newAds?.length) {
      const lastAdsSet = new Set(
        ads.slice(-COUNT_TO_FIND_DUPLICATES).map(ad => ad.id)
      );
      const filteredNewAds = newAds?.filter(ad => !lastAdsSet.has(ad.id)) || [];
      setAds([...ads, ...filteredNewAds]);
    }

    if (data?.ads?.pageInfo?.endCursor) {
      setNextCursor(data?.ads?.pageInfo?.endCursor);
    }
  }

  useEffect(() => {
    setIsFinished(ads.length >= maxCount);
  }, [ads.length]);

  return { onLoadMore, isFinished };
}
