import { Seo } from '@/components/Seo/Seo';
import { HomeView } from '@/views/Home/Home.view';
import { withPreRenderSettings } from '@/utils/hocs';
import { SeoFullData } from '@/api/types/seo.types';
import { getSeoFullData } from '@/api/helpers/seo.helpers';

interface Props {
  seoFullData: SeoFullData | null;
}

export default function Home({ seoFullData }: Props) {
  return (
    <>
      <Seo {...seoFullData} />
      <HomeView />
    </>
  );
}

export const getServerSideProps = withPreRenderSettings(
  async (context, client) => {
    const seoFullData = await getSeoFullData(client, context);

    return {
      props: {
        seoFullData: seoFullData ?? null,
      },
    };
  }
);
