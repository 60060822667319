import { useEffect } from 'react';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import {
  calculateBannersHeight,
  clearStoredPremiumAdsData,
  clearStoredScrollPositionData,
} from '@/components/ScrollSaver/ScrollSaver.helpers';
import { useAdsStore } from '@/store/useAdsStore';
import { Ad } from '@/api/types/ads.types';
import {
  DELAY_TO_HIDE_HEADER,
  DELAY_TO_RESTORE_POSITION,
} from '@/components/ScrollSaver/ScrollSaver.constants';

export const ScrollSaver = () => {
  const [setAds, setNextCursor] = useAdsStore(state => [
    state.setPremiumAdsHomePage,
    state.setPremiumAdsNextCursorHomePage,
  ]);

  function handleStoredPremiumAds() {
    const premiumAds = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.PREMIUM_ADS) ?? '[]'
    ) as Ad[];
    const premiumAdsNextCursor =
      localStorage.getItem(STORAGE_KEYS.PREMIUM_ADS_NEXT_CURSOR) ?? '';

    if (premiumAds.length) {
      setAds(premiumAds);
    }

    if (premiumAdsNextCursor) {
      setNextCursor(premiumAdsNextCursor);
    }
  }

  function scrollToTarget(finalScrollPosition: number) {
    window.scrollTo(0, finalScrollPosition);

    setTimeout(() => {
      window.scrollTo(0, finalScrollPosition + 1);

      clearStoredScrollPositionData();
      clearStoredPremiumAdsData();
    }, DELAY_TO_HIDE_HEADER);
  }

  useEffect(() => {
    if (
      localStorage.getItem(STORAGE_KEYS.NEED_TO_RESTORE_SCROLL_POSITION) ===
      'false'
    ) {
      localStorage.removeItem(STORAGE_KEYS.NEED_TO_RESTORE_SCROLL_POSITION);
      clearStoredScrollPositionData();
      clearStoredPremiumAdsData();
      return;
    }
    handleStoredPremiumAds();

    setTimeout(() => {
      const previousBannersHeight = Number(
        localStorage.getItem(STORAGE_KEYS.PREVIOUS_BANNERS_HEIGHT) ?? 0
      );

      const newBannersHeight = calculateBannersHeight(false);

      const lastScrollPosition = Number(
        localStorage.getItem(STORAGE_KEYS.LAST_SCROLL_POSITION)
      );

      const finalScrollPosition =
        lastScrollPosition - previousBannersHeight + newBannersHeight;

      const isNeedToScrollTo = newBannersHeight !== finalScrollPosition;

      if (isNeedToScrollTo) {
        scrollToTarget(finalScrollPosition);
      }
    }, DELAY_TO_RESTORE_POSITION);
  }, []);

  return null;
};
